import React from "react"

import type { CreateAccountPagePropsType } from "../components/CreateAccount/CreateAccountAssets"

import { PublicRoute } from "@lesmills/gatsby-theme-common"
import CreateAccount from "../components/CreateAccount/CreateAccountPage"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

const CreateAffiliateAccountPage = ({
  location,
  pageContext,
  data,
}: CreateAccountPagePropsType) => {
  const lang = pageContext.unPublishedLang || pageContext.lang
  const {
    prismicLayout = {},
    prismicCreateAccountPage = {},
    prismicUpdatePaymentDetailsPage = {},
  } = data || {}

  if (!data) {
    return null
  }

  return (
    <PublicRoute
      component={props =>
        CreateAccount(
          prismicCreateAccountPage.data,
          prismicLayout.data,
          prismicUpdatePaymentDetailsPage.data,
          lang,
          location,
          true,
          props
        )
      }
      lang={lang}
      location={location}
    />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutCreateAccountPage
    }
    prismicCreateAccountPage(lang: { eq: $lang }) {
      ...CreateAccountPage
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`

export default withPreview(CreateAffiliateAccountPage)
